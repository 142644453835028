<template>
    <div class="container">
        <img alt="五天交付 报名就送智能升降调味栏 " src="https://zdd-mall.oss-cn-hangzhou.aliyuncs.com/h5/tiktok/main-pic.1ef5cccd.png" id="main-pic">
        <div class="form-box">
            <input
                class="input"
                placeholder="姓名"
                placeholder-class="input-placeholder"
                @input="(e) => onInput('user_name',e)"
            />
            <input
                class="phone input"
                type="tel"
                maxlength="11"
                placeholder="手机号"
                placeholder-class="input-placeholder"
                @input="(e) => onInput('telephone',e)"
            />
            <div class="list" @click="onShow('address')">
                <div class="input input-placeholder" >{{addressValue || "城市及分区"}}</div>
                <img class="my-icon" src="https://zdd-mall.oss-cn-hangzhou.aliyuncs.com/h5/tiktok/arrow-r.png" alt="">
            </div>
            <div class="list" @click="onShow('house_status')">
                <div class="input input-placeholder" >{{houseValue || "房屋状态"}}</div>
                <img class="my-icon" src="https://zdd-mall.oss-cn-hangzhou.aliyuncs.com/h5/tiktok/arrow-r.png" alt="">
            </div>
            <button class="submite-btn" @click="onsubmit">立即预约</button>
            <Popup class="pop-up" v-model:show="pickerShow" position="bottom">
                <Area v-if="pickerType" :areaList="columns" @confirm="pickerConfirm" @cancel="closeModal"></Area>
                <Picker v-else class="picker-list" :columns="columns" @confirm="pickerConfirm" @cancel="closeModal"/>
            </Popup>
        </div>
        <div class="content-box">
            <Swipe class="swipe-box" :autoplay="3000" indicator-color="white">
                <SwipeItem>
                    <img class="img" src="https://zdd-mall.oss-cn-hangzhou.aliyuncs.com/h5/tiktok/swiper-1.0482c880.png" alt="">
                </SwipeItem>
                <SwipeItem>
                    <img class="img" src="https://zdd-mall.oss-cn-hangzhou.aliyuncs.com/h5/tiktok/swiper-2.7ed0dba0.png" alt="">
                </SwipeItem>
                <SwipeItem>
                    <img class="img" src="https://zdd-mall.oss-cn-hangzhou.aliyuncs.com/h5/tiktok/swiper-3.a38e22f8.png" alt="">
                </SwipeItem>
            </Swipe>
            
            <h3 class="title">选择氧屋东西的理由</h3>
            <ul class="reason-ul">
                <li class="li">
                    <img class="img" src="https://zdd-mall.oss-cn-hangzhou.aliyuncs.com/h5/tiktok/reason-1.64fe1d2d.png" alt="">
                    <div class="content">
                        <h4 class="h4"><span class="ordinal">1</span>「 可以深呼吸 」的厨房</h4>
                        <div class="text">实木多层板材使用专利无醛胶，风墙烟灶隔离油烟，这是一个可以深呼吸的厨房</div>
                    </div>
                </li>
                <li class="li">
                    <div class="content">
                        <h4 class="h4"><span class="ordinal">2</span>「 厨房装备 」全面升级</h4>
                        <div class="text">功能智能双升级，省时省心又便利</div>
                    </div>
                    <img class="img" src="https://zdd-mall.oss-cn-hangzhou.aliyuncs.com/h5/tiktok/reason-2.ec2f0baf.png" alt="">
                </li>
                <li class="li">
                    <img class="img" src="https://zdd-mall.oss-cn-hangzhou.aliyuncs.com/h5/tiktok/reason-3.72e8d408.png" alt="">
                    <div class="content">
                        <h4 class="h4"><span class="ordinal">3</span>「 灵活收纳 」空间巧用</h4>
                        <div class="text">无死角收纳设计，易收易取，收纳空间更加合理</div>
                    </div>
                </li>
                <li class="li">
                    <div class="content">
                        <h4 class="h4"><span class="ordinal">4</span>「 暖心关爱 」细节体现</h4>
                        <div class="text">灭火器，儿童锁，让保护从细节出发</div>
                    </div>
                    <img class="img" src="https://zdd-mall.oss-cn-hangzhou.aliyuncs.com/h5/tiktok/reason-4.b453a2a7.png" alt="">
                </li>
                <li class="li">
                    <img class="img" src="https://zdd-mall.oss-cn-hangzhou.aliyuncs.com/h5/tiktok/reason-5.6cc34194.png" alt="">
                    <div class="content">
                        <h4 class="h4"><span class="ordinal">5</span>「 美学大师」精心设计</h4>
                        <div class="text">陈飞杰老师倾心打造，持久耐看，让厨房成为生活T台</div>
                    </div>
                </li>
                <li class="li">
                    <div class="content">
                        <h4 class="h4"><span class="ordinal">6</span>厨房换新「 仅用5天 」</h4>
                        <div class="text">装前保护收纳，过程无打扰并可监控装后保洁，5天就够</div>
                    </div>
                    <img class="img" src="https://zdd-mall.oss-cn-hangzhou.aliyuncs.com/h5/tiktok/reason-6.5149350d.png" alt="">
                </li>
                <li class="li">
                    <img class="img" src="https://zdd-mall.oss-cn-hangzhou.aliyuncs.com/h5/tiktok/reason-7.268d30de.png" alt="">
                    <div class="content">
                        <h4 class="h4"><span class="ordinal">7</span>「 售后无忧 」一键即达</h4>
                        <div class="text">硬装保质20年，电器2年只换不修<br/>厨房维修一键客服解决，同城半日即达</div>
                    </div>
                </li>
            </ul>

            <h3 class="title">设计案例</h3>
            <ul class="case-ul">
                <li class="li">
                    <div class="img-list">
                        <img class="img" src="https://zdd-mall.oss-cn-hangzhou.aliyuncs.com/h5/tiktok/design-1-1.68ed1520.png" alt="">
                        <img class="img" src="https://zdd-mall.oss-cn-hangzhou.aliyuncs.com/h5/tiktok/design-1-2.e5a2681d.png" alt="">
                        <img class="img-tag tag1" src="https://zdd-mall.oss-cn-hangzhou.aliyuncs.com/h5/tiktok/5day.png" alt="">
                        <img class="img-tag tag2" src="https://zdd-mall.oss-cn-hangzhou.aliyuncs.com/h5/tiktok/5day-after.png" alt="">
                    </div>
                    <div class="content">
                        <h4 class="h4">桂花城</h4>
                        <div class="description">
                            <span class="area">厨房面积：7.16㎡</span>
                            <span class="line"></span>
                            <span class="tag">#老小区厨房#</span>
                        </div>
                    </div>
                </li>
                <li class="li">
                    <div class="img-list">
                        <img class="img" src="https://zdd-mall.oss-cn-hangzhou.aliyuncs.com/h5/tiktok/design-2-1.99661878.png" alt="">
                        <img class="img" src="https://zdd-mall.oss-cn-hangzhou.aliyuncs.com/h5/tiktok/design-2-2.3c36d1df.png" alt="">
                        <img class="img-tag tag1" src="https://zdd-mall.oss-cn-hangzhou.aliyuncs.com/h5/tiktok/5day.png" alt="">
                        <img class="img-tag tag2" src="https://zdd-mall.oss-cn-hangzhou.aliyuncs.com/h5/tiktok/5day-after.png" alt="">
                    </div>
                    <div class="content">
                        <h4 class="h4">星洲花园</h4>
                        <div class="description">
                            <span class="area">厨房面积：8.5㎡</span>
                            <span class="line"></span>
                            <span class="tag">#老校区学区房#</span>
                        </div>
                    </div>
                </li>
            </ul>

            <div class="report-box" @click="toTop">
                <img class="img" src="https://zdd-mall.oss-cn-hangzhou.aliyuncs.com/h5/tiktok/report.55267030.png" alt="">
                <div class="content">
                    <div class="description">
                        <h4 class="h4">点击<b>"预约报名"</b></h4>
                        <span class="text">填写信息即可获取氧屋一站式服务</span>
                    </div>
                    <img class="l-img" src="https://zdd-mall.oss-cn-hangzhou.aliyuncs.com/h5/tiktok/icon.png" alt="">
                </div>
            </div>
        </div>
    </div>

</template>
<style scope>
@import url('./main.css');
</style>
<script src="./main.js"></script>

