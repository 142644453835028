<template>
	<MainPage></MainPage>
</template>

<script>
import MainPage from './view/main.vue'

export default {
	name: 'App',
	// 从父级接收参数
	props:{},
	// 监听页面参数
	data() {
		return {}
	},
	// 将计算属性混入到组件实例中
	computed:{},
	methods:{},
	components: {
		MainPage
	}
}
</script>

<style>
@import './assets/font/D-DINExp/D-DINExp.wxss';
@import './assets/font/D-DINExp-Bold/D-DINExp-Bold.wxss';
html,
body,
ul,
li,
h3,
h4,
input,
image{
	margin: 0;
	padding: 0;
}
img{
	display: block;
}
input{
	display: inline-block;
	border: none;
	border-radius: 0;
}

#app {
	/* font-family: Avenir, Helvetica, Arial, sans-serif; */
	font-family: "D-DINExp-Bold";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}
.container{
	width: 100%;
	height: 100%;
	overflow-y: auto;
}
</style>
