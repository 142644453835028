
import { 
    Area,
    Popup,
    Picker,
    Swipe,
    Toast,
    SwipeItem
} from 'vant';
import { areaList } from '@vant/area-data';
export default {
	name: 'App',
	components: {
        Area,
        Popup,
        Picker,
        Swipe,
        Toast,
        SwipeItem
	},
	// 从父级接收参数
	props:{},
	// 监听页面参数
	data() {
		return {
            addressArr:[],
            formData:{},
            addressValue: undefined,
            houseValue: undefined,
            // addressValue:"城市及分区",
            // houseValue:"房屋状态",
            pickerType: true,
            pickerShow: false,
            columns: [],
        }
	},
	// 将计算属性混入到组件实例中
	computed:{},
	methods:{
        // 输入框变化
        onInput(type, e){
            const { value } = e.target || {};
            this.formData[type] = value;
            if(typeof(value) === "undefined" || value === "") this.formWarning(type, true)
            else this.formWarning(type, false)
        },
        onShow(type){
            if(type === "address"){
                this.columns = areaList;
                this.pickerShow = true;
                this.pickerType = true
            }else if(type === "house_status"){
                this.columns = [ '新房装修', '旧房改造' ]
                this.pickerShow = true;
                this.pickerType = false
            }
        },
        pickerConfirm(e){
            if(this.pickerType){
                this.addressArr = e;
                this.addressValue = e[0]?.name + " " + e[1]?.name + " " + e[2]?.name;
                this.formWarning("address", false);
            } else {
                this.houseValue = e
                this.formWarning("house_status", false);
            }
            this.closeModal()
        },
        closeModal(){
            this.pickerShow = false;
        },
        formWarning(name, type){
            const eFormBox = document.querySelector(".form-box");
            const eInput = eFormBox.querySelectorAll(".input");
            const eList = eFormBox.querySelectorAll(".list");
            if(name === "user_name"){
                eInput[0].className = type?"input error-input":"input";
                eInput[0].placeholder = type?"请输入姓名":"姓名";
            } else if(name === "telephone"){
                eInput[1].className = type?"phone input error-input":"phone input";
                eInput[1].placeholder = type?"请输入手机号":"手机号";
            } else if(name === "address"){
                eInput[2].className = type?"input error-input":"input";
                eList[0].className = type?"list error-input":"list";
                eInput[2].placeholder = type?"请选择城市":"城市及分区";
            } else if(name === "house_status"){
                eInput[3].className = type?"input error-input":"input";
                eList[1].className = type?"list error-input":"list";
                eInput[3].placeholder = type?"请选择房屋状态":"房屋状态";
            }

        },
        toTop(){
            // let FormBox = document.querySelector(".form-box");
            window.scrollTo({
                top: 0, 
                // top: FormBox.offsetTop-100, 
                behavior: "smooth" // 平滑滚动
              })
        },
        onsubmit(){
            const arr = [ '新房装修', '旧房改造' ];
            const status = [ 10, 20]
            let body = {
                "user_name": this.formData.user_name,
                "telephone": this.formData.telephone,
                "address": this.addressValue,
                "house_status": arr.indexOf(this.houseValue),
            }
            // 手机号正则匹配
            const regular_mobile =  /^\d{11}/g;
            if(typeof(body["user_name"]) === "undefined" || body["user_name"] === "") this.formWarning("user_name", true);
            else if(typeof(body["telephone"]) === "undefined" || body["telephone"] === "") this.formWarning("telephone", true);
            else if(typeof(body["address"]) === "undefined" || body["address"] === "") this.formWarning("address", true);
            else if(body["house_status"] === -1) this.formWarning("house_status", true);
            else {
                if(regular_mobile.test(body["telephone"])){
                    body["house_status"] = status[body.house_status]
                    this.onFetch(body);
                } else {
                    Toast.fail({
                        message: '请输入正确的手机号',
                        forbidClick: true,
                    })
                    this.formWarning("telephone", true);
                }
            }
        },
        onFetch(body){
            const params = window.location.search || "";
            const env = params.split("=")[1];
            // 
            fetch(`${env === "test"?'https://tpapi.zhaidoudou.com:9009':'https://pay.zhaidoudou.com'}/api/h5.apply/dyApply`,{
                method: "POST",
                headers:{
                    "content-type": "application/json",
                },
                body: JSON.stringify(body)
            })
            .then(res => res.json())
            .then(res => {
                if(res.code==0){
                    Toast.success({
                        message: res.msg,
                        forbidClick: true,
                        onClose:()=> window.location.reload()
                    });
                } else {
                    Toast.fail({
                        message: res.msg,
                        forbidClick: true,
                    });
                }
            })
            .catch(err => {
                Toast.fail({
                    message: err.msg,
                    forbidClick: true,
                });
            })
        }
	},
    created() {},
}